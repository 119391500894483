body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.arrow {
  border: solid #a19b9b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3.5px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrowExtraSmall {
  border: solid #a19b9b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 1px;
  margin-right: 1px;
}

.arrowSmall {
  border: solid #a19b9b;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 3.5px;
  margin-bottom: 1.5px;
  margin-right: 1.5px;
}

.arrowMedium {
  border: solid #a19b9b;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 3.5px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.arrowWhite {
  /* border: solid #125132; */
  border: solid #626262;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3.5px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.arrowWhiteExtraSmall {
  /* border: solid #125132; */
  border: solid #626262;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 1px;
  margin-bottom: 1px;
  margin-right: 1px;
}

.arrowWhiteSmall {
  /* border: solid #125132; */
  border: solid #626262;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-bottom: 1.5px;
  margin-right: 1.5px;
}

.arrowWhiteMedium {
  /* border: solid #125132; */
  border: solid #626262;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 2px;
  margin-right: 2px;
}


